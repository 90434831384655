@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  /* background: linear-gradient(0deg, #9a0b08, #d7042b); */
  font-family: "Bricolage Grotesque", sans-serif;
  scroll-behavior: smooth;
  transition: ease-in 1s;
  min-height: calc(100vh - 1rem);
  background-color: #f0f0f0;
}

.container.header {
  margin-bottom: 2.5rem;
}

.Logo {
  font-weight: 900;
}

.welcome {
  background: linear-gradient(0deg, #9a0b08, #d7042b);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  font-size: 2.5em;
  position: relative;
  position: fixed;
  transition: all ease 2s;
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: opacity 0.5s, visibility 0s 0.5s;
  visibility: visible;
}

.welcome h1 {
  text-align: center;
  color: #9a0b08;
  top: 37%;
  position: relative;
  animation: welcome 1.25s;
}

.welcome-finished {
  transition: opacity 0.5s, visibility 0s 0.5s;
  animation: welcomehide 1s;
  transition: all ease 1s;
}

@keyframes welcome {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes welcomehide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.noscroll {
  height: 100%;
  overflow: hidden;
}

.CookieConsent {
  border-radius: 8px 8px 0 0;
  background: #d7042b !important;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

hr {
  border-top: 0.3em solid #9a0b08;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.geschichte {
  color: #9a0b08;
}

.BlogTitle {
  color: #9a0b08;
}

.BlogSubTitle {
  color: #9a0b08;
}

.BlogText {
  color: #9a0b08;
}

.BlogPicture {
  color: #9a0b08;
  max-width: 100%;
}

.container {
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 1280px;
  width: 90%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

.Logo {
  position: relative;
  margin-left: 0;
  margin-top: 0;
  width: 50%;
  transition: all 0.3s;
  user-select: none;
  font-size: 36px;
}

.Header {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 100%;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
  transition: ease-out 0.5s;
  user-select: none;
}

.Header:hover {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  transition: ease-in 0.5s;
  transform: translate3d(0, -5px, 0);
}

.HeaderText {
  color: #9a0b08;
  font-size: 3.25em;
  max-width: 99%;
  user-select: none;
  font-weight: 700;
}

.widgets {
  display: flex;
  margin-top: 2rem;
}

.widgets .widget {
  position: relative;
  padding: 0.5rem;
  width: 17.5rem;
  height: 10rem;
  background-color: #cf162e7e;
  user-select: none;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.125);
}

.widgets .widget h2 {
  margin-top: 0.25rem;
  width: 100%;
  color: #9a0b08;
  font-size: 20px;
}

.widgets .widget .incidenceDesc {
  color: #9a0b08;
}

.widgets .widget .incidence {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  user-select: none;
}

.widgets .widget .color {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #9a0b08;
  bottom: 0.25rem;
  right: 0.25rem;
}

.widgets .widget .color.green {
  background-color: #36fd2f;
}

.widgets .widget .color.orange {
  background-color: #ffe135;
}

.widgets .widget .color.red {
  background-color: #ff3532;
}

.widgets .widget .incidence .incidenceAmount {
  color: #9a0b08;
  font-size: 32px;
}

.widgets .widget .source {
  color: #9a0b08;
  position: absolute;
  bottom: 0.5rem;
  opacity: 0.5;
  user-select: none;
}

.widgets .widget .incidence .change {
  padding: 0 0.25rem 0 0.25rem;
  background-color: #c5142b;
  color: #9a0b08;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 0.5rem;
}

.widgets .widget .incidence .change svg {
  width: 25px;
}

.widgets .widget .incidence .change.rising {
  background-color: #750000b2;
}

.widgets .widget .incidence .change.sinking {
  background-color: #50d361d0;
}

.downArrow {
  position: relative;
  margin-top: 10%;
  margin-bottom: 7.5%;
  left: 50%;
  fill: #fff;
}

.Title {
  color: #9a0b08;
  font-size: 3em;
  margin-top: 1%;
  font-weight: 600;
}

.Subtitle {
  color: #9a0b08;
  font-size: 2.8em;
}

.CarPicture {
  width: 75%;
  transition: ease-in 0.5s;
}

.CarPicture:hover {
  transition: ease-in 0.5s;
  transform: translate3d(0, -5px, 0);
}

.CarInfo {
  font-size: 2em;
  position: relative;
  color: #9a0b08;
}

.LatestTitle {
  color: #9a0b08;
  font-size: 4em;
  margin-top: 5%;
}

article .article-event {
  width: 100%;
  margin-top: 2rem;
  border-radius: 8px;
  padding: 2rem;
  background-color: #de16314d;
}

article .article-event .flex {
  display: flex;
  align-items: center;
}

article .article-event .flex span {
  font-size: 25px;
  margin-left: 1rem;
  color: #e2e2e2;
}

article .meta {
  color: rgb(209, 209, 209);
  font-size: 22px;
  user-select: none;
}

article .products .product.divider {
  margin-top: 2.5rem;
}

article img {
  max-width: 100%;
  border-radius: 8px;
}

article .products .product {
  background-color: #de16314d;
  width: 100%;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  user-select: none;
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.article-info {
  background-color: #de16314d;
  color: #9a0b08;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  user-select: none;
  width: calc(100% + 2rem);
}

.article-info svg {
  width: 2rem;
  margin-right: 1rem;
}

article .products .product:hover {
  background-color: #e6213b98;
  transform: translate(0, -2.5px);
}

article .products .product .price {
  color: #e0e0e0;
}

.article-buttons {
  display: flex;
  gap: 1rem;
  width: 5rem;
}

.article-buttons .button.cal {
  text-align: center;
}

.article-buttons .button.wa {
  background: rgba(204, 204, 204, 0.75);
}

.LatestSubTitle {
  color: #9a0b08;
  font-size: 2em;
}

.LatestPicture {
  width: 100%;
  user-select: none;
  border-radius: 10px;
  transition: ease-in 0.5s;
}

.LatestPicture:hover {
  transition: ease-in 0.5s;
  transform: translate3d(0, -5px, 0);
}

.LatestText {
  width: 100%;
  max-width: 100%;
  color: #9a0b08;
  font-size: 1.25em;
}

.footerContainer {
  user-select: none;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.buttons .button {
  width: 50%;
}

.button {
  background-color: #9a0b08;
  color: #fff;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  cursor: pointer;
  white-space: nowrap;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 12px 18px;
  text-decoration: none;
  margin: 0 auto;
  display: block;
  margin-top: 5%;
  font-size: 1.125em;
  transition: background 0.5s ease, transform 0.5s ease;
  user-select: none !important;
  width: auto;
}

.button:hover {
  background-color: #c6110d;
}

.button:active {
  transform: translate(0, -5px);
}

footer a:focus,
footer a:hover {
  cursor: pointer;
}

.PreviewPicture {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 10px !important;
}

.PreviewTitle {
  color: #9a0b08;
}

.jugendfeuerwehr {
  color: #000;
  background-color: #fe3852;
  width: 100%;
  height: 25em;
  margin-top: 5%;
  padding: 0.1%;
  border-radius: 10px;
}

.today-number {
  color: #9a0b08;
  font-size: 8em;
  position: relative;
  user-select: none;
}

.today-month {
  color: #9a0b08;
  font-size: 1.5em;
  position: relative;
  margin-top: -7.35%;
  margin-left: 15%;
  user-select: none;
}

.facebook {
  width: 50px;
  margin-top: 4%;
}

.contact {
  width: 50%;
  margin-top: 15%;
}

.contact h1 {
  color: #9a0b08;
  font-size: 2.5em;
}

.contact h2 {
  color: #9a0b08;
  font-size: 1.75em;
}

#map {
  height: 100%;
}

footer {
  margin-bottom: 5%;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: #000;
  background-color: rgba(222, 22, 49, 0.95);
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

.open {
  visibility: visible;
  transition: opacity 0.5s;
  opacity: 1;
}

.overlay-content {
  position: relative;
  top: 10%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 10px;
  text-decoration: none;
  font-size: 36px;
  color: #fff;
  display: block;
  transition: 0.3s;
  user-select: none;
}

.overlay a:focus,
.overlay a:hover {
  color: rgb(216, 216, 216);
  cursor: pointer;
}

.overlay .closebtn {
  position: absolute;
  right: 0;
  margin-top: 0.25rem;
  width: 3rem;
}

.closebtn:focus,
.closebtn:hover {
  cursor: pointer;
}

.menuButton svg {
  background-color: #9a0b08;
  width: 3rem;
  height: 3rem;
  padding: .75rem;
  position: absolute;
  top: 0.5rem;
  cursor: pointer;
  right: 0;
}

.menu {
  position: relative;
  float: right;
  margin-top: -3.25%;
}

.menu:focus,
.menu:hover {
  cursor: pointer;
}

.events {
  color: #9a0b08;
}

.events .event {
  background-color: #de16314d;
  width: 100%;
  color: #9a0b08;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 8px;
  user-select: none;
  font-size: 22px;
  left: 0;
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.events .event:hover {
  background-color: #e6213b98;
  transform: translate(0, -2.5px);
}

.checkedevents {
  opacity: 0.5;
}

.map {
  width: 500px;
  height: 250px;
  float: right;
  margin-right: 1%;
  margin-top: -20%;
  margin-bottom: 5%;
  border-radius: 50px;
}

.checkedevents li {
  color: rgb(255, 255, 255, 0.5);
  font-size: 18px;
}

.impressum {
  color: #9a0b08;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }

  .overlay .closebtn {
    top: 0;
    right: 35px;
  }
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

::selection {
  background-color: #9a0b08;
  color: #000;
}

.disabled {
  cursor: not-allowed !important;
  color: #d5d5d5 !important;
}

.current {
  cursor: not-allowed !important;
  text-decoration: underline !important;
  text-decoration-style: solid;
}

.errorbig {
  color: #9a0b08;
  font-size: 20vw;
  margin: 0;
}

.error {
  color: #9a0b08;
  font-size: 2.5em;
}

.login-name {
  border: none;
  width: 50%;
  height: 40px;
  border-radius: 10px;
  padding: 7px;
  position: relative;
  margin-left: 23.5%;
  margin-top: 5%;
}

.login-pw {
  margin-top: 3.5%;
  border: none;
  width: 50%;
  height: 40px;
  border-radius: 10px;
  padding: 7px;
  position: relative;
  margin-left: 23.5%;
}

.edit-name {
  border: none;
  width: 50%;
  height: 40px;
  border-radius: 10px;
  padding: 7px;
  position: relative;
  margin-right: 0%;
  margin-top: 5%;
}

.edit-summary {
  border: none;
  width: 50%;
  height: 100px;
  border-radius: 10px;
  padding: 7px;
  position: relative;
  margin-right: 0%;
  margin-top: 5%;
}

.tableHead {
  color: #9a0b08;
  font-size: 1.5em;
  padding: 10px;
}

.info {
  background-color: rgba(222, 222, 222, 0.75);
  border-radius: 10px;
  color: #9a0b08;
  width: 10vw;
  height: 10vw;
  padding: 15px;
  margin-bottom: 5%;
}

.info h1 {
  width: 90%;
  max-width: 95%;
  font-size: 2.5em;
  color: #9a0b08;
}

.info h2 {
  font-size: 1em;
  color: #9a0b08;
}

@media (max-width: 860px) {
  .geschichte h1 {
    font-size: 2.5em;
  }

  .widgets .widget {
    width: 100%;
  }

  .Logo {
    position: relative;
    margin-left: 12.5%;
    margin-right: auto;
    margin-top: 2%;
    width: 75%;
    transition: all 0.3s;
  }

  .Header {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5%;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    transition: ease-out 0.5s;
  }

  .Header:hover {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    transition: ease-in 0.5s;
  }

  .HeaderText {
    color: #9a0b08;
    font-size: 2.5em;
    width: 80%;
  }

  .downArrow {
    position: relative;
    margin-top: 10%;
    margin-bottom: 10%;
    left: 50%;
    fill: #fff;
  }

  .Title {
    color: #9a0b08;
    font-size: 3em;
    margin-top: 1%;
    max-width: 100%;
  }

  .Subtitle {
    color: #9a0b08;
    font-size: 2.8em;
    max-width: 100%;
  }

  .CarPicture {
    width: 100%;
    border-radius: 7px;
    max-width: 100%;
  }

  .CarInfo {
    font-size: 1.5em;
    position: relative;
    color: #9a0b08;
    max-width: 90%;
  }

  .LatestTitle {
    color: #9a0b08;
    font-size: 4em;
    margin-top: 5%;
    max-width: 100%;
  }

  .LatestSubTitle {
    color: #9a0b08;
    font-size: 2em;
    max-width: 100%;
  }

  .LatestPicture {
    width: 100%;
    border-radius: 10px;
    max-width: 100%;
    transition: ease-in 1s;
  }

  .LatestText {
    width: 100%;
    color: #9a0b08;
    font-size: 1.25em;
    max-width: 100%;
  }

  .button {
    user-select: none;
    background-color: #9a0b08;
    color: #de1631;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.03em;
    cursor: pointer;
    white-space: nowrap;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 12px 18px;
    text-decoration: none;
    margin: 0 auto;
    display: block;
    margin-top: 5%;
    font-size: 1.125em;
    transition: ease-in 1s;
  }

  .PreviewPicture {
    position: relative;
    width: 250px;
    height: 250px;
    max-width: 100%;
    border-radius: 10px !important;
  }

  .PreviewTitle {
    color: #9a0b08;
    max-width: 100%;
  }

  .jugendfeuerwehr {
    color: #000;
    background-color: #fe3852;
    width: 100%;
    height: 25em;
    margin-top: 5%;
    padding: 0.1%;
    border-radius: 10px;
    max-width: 100%;
  }

  .jugendfeuerwehr h1 {
    max-width: 100%;
    font-size: 1.85em;
  }

  .today-number {
    color: #9a0b08;
    font-size: 8em;
    position: relative;
    max-width: 100%;
    user-select: none;
  }

  .today-month {
    color: #9a0b08;
    font-size: 1.5em;
    position: relative;
    margin-top: -7.35%;
    margin-left: 15%;
  }

  .facebook {
    width: 50px;
    margin-top: 4%;
  }

  .contact {
    width: 90%;
    margin-top: 15%;
    position: relative;
  }

  .contact h1 {
    color: #9a0b08;
    font-size: 2.5em;
  }

  .contact h2 {
    color: #9a0b08;
    font-size: 1.75em;
  }

  #map {
    height: 100%;
    position: relative;
  }

  .map {
    width: 500px;
    height: 250px;
    position: relative;
    float: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 5%;
    border-radius: 50px;
  }

  .impressum {
    color: #9a0b08;
  }

  .overlay-content {
    position: relative;
    top: 15%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    user-select: none;
  }

  .overlay a {
    user-select: none;
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #9a0b08;
    display: block;
    transition: 0.3s;
  }

  .overlay a:focus,
  .overlay a:hover {
    color: #9a0b08;
    font-size: 42px;
  }

  .disable-dbl-tap-zoom {
    touch-action: manipulation;
  }

  .overlay .closebtn {
    position: absolute;
    top: 10%;
    font-size: 60px;
  }

  .menu {
    top: 3%;
    right: 3%;
    position: absolute;
  }

  .menu:focus {
    cursor: pointer;
  }

  .events {
    color: #9a0b08;
  }

  .events li {
    color: #9a0b08;
    font-size: 22px;
  }

  .checkedevents {
    color: #9a0b08;
  }

  .checkedevents li {
    color: rgb(255, 255, 255, 0.5);
    font-size: 18px;
  }

  @media screen and (max-height: 450px) {
    .overlay a {
      font-size: 20px;
    }

    .overlay .closebtn {
      font-size: 40px;
      top: 5px;
      right: 35px;
    }
  }

  .bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
  }
}

.nav-anim-enter {
  opacity: 0;
  transition: opacity ease 0.125s, transform ease 0.25s;
}

.nav-anim-enter-active {
  opacity: 1;
  transition: opacity ease 0.125s, transform ease 0.25s;
}

.nav-anim-exit {
  opacity: 1;
  transition: opacity ease 0.125s, transform ease 0.25s;
}

.nav-anim-exit-active {
  opacity: 0;
  transition: opacity ease 0.125s, transform ease 0.25s;
}

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #9a0b08;
  z-index: 99;
}

.loader .loader-object {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  background-color: #d5d5d5;
  border-radius: 50%;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 7.5px rgba(255, 255, 255, 0.5);
  }

  100% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: #9a0b08;
  border: 100px none #9a0b08;
  border-radius: 50px;
  transition: background-color 0.25s ease;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ac0d0a;
}
::-webkit-scrollbar-thumb:active {
  background-color: #9a0b08;
}
::-webkit-scrollbar-track {
  border: 100px none #9a0b08;
  background-color: #770908;
}
::-webkit-scrollbar-track:hover {
}
::-webkit-scrollbar-track:active {
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.Picture {
  width: 100%;
}

* {
  color: #9a0b08;
}

.Text {
  margin-top: 5px;
  margin-bottom: 15px;
}

.mb {
  margin-bottom: 15px;
}

.mt-0 {
  margin-top: 0;
}

p {
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 500;
  font-size: 18px;
}
